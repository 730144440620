.container-css {
  display: inline-flex;
  justify-content: flex-start;
}
.container-css .left {
  width: 300px;
  margin-right: 20px;
}
.container-css .left .item-css {
  width: 300px;
  height: 30px;
  padding-bottom: 16px;
  border-bottom: 1px solid #EEEEEE;
  margin-bottom: 16px;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
}
.container-css .left .item-css .headline {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  line-height: 14px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.container-css .left .item-css .content {
  font-weight: 400;
  line-height: 14px;
  text-align: right;
  font-style: normal;
  text-transform: none;
}
.container-css .left .item-css .state-css {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}
.container-css .left .item-css .state-css .select-css {
  width: 110px;
}
.container-css .left .item-content {
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.container-css .left .item-content .title {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  line-height: 14px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  padding-bottom: 12px;
}
.container-css .left .item-content .content {
  height: 305px;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.container-css .left .item-content .img-list {
  width: 100%;
  overflow-x: auto;
  /* 允许水平滚动 */
  white-space: nowrap;
  /* 防止内容换行 */
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
}
.container-css .left .item-content .img-list .img-css {
  flex-shrink: 0;
  padding: 5px;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.container-css .right {
  width: 645px;
}
.container-css .right .title-field {
  height: 14px;
  font-weight: 600;
  font-size: 14px;
  color: #666666;
  line-height: 14px;
  text-align: right;
  font-style: normal;
  text-transform: none;
  margin-bottom: 16px;
  cursor: pointer;
}
.container-css .right .message-field {
  width: 100%;
  height: 512px;
  background: #F5F7FA;
  border-radius: 2px 2px 0 0;
  padding-top: 20px;
  display: inline-flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}
.container-css .right .message-field .my-message {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
}
.container-css .right .message-field .my-message .message-css {
  display: inline-flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
}
.container-css .right .message-field .my-message .message-css .head {
  padding-right: 20px;
}
.container-css .right .message-field .my-message .message-css .head .head-css {
  width: 32px;
  height: 32px;
}
.container-css .right .message-field .my-message .message-css .message {
  max-width: 550px;
  padding-right: 8px;
  display: inline-flex;
  flex-direction: column;
}
.container-css .right .message-field .my-message .message-css .message .name-css {
  padding-bottom: 4px;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  line-height: 12px;
  text-align: right;
  font-style: normal;
  text-transform: none;
}
.container-css .right .message-field .my-message .message-css .message .msg-css {
  padding: 10px;
  background: #24B69B;
  border-radius: 8px 8px 8px 8px;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.container-css .right .message-field .my-message .time-css {
  width: 100%;
  padding: 20px 0;
  font-weight: 400;
  font-size: 10px;
  color: #999999;
  line-height: 10px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}
.container-css .right .message-field .user-message {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
}
.container-css .right .message-field .user-message .message-css {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
}
.container-css .right .message-field .user-message .message-css .head {
  padding-left: 20px;
}
.container-css .right .message-field .user-message .message-css .head .head-css {
  width: 32px;
  height: 32px;
}
.container-css .right .message-field .user-message .message-css .message {
  max-width: 550px;
  padding-left: 8px;
  display: inline-flex;
  flex-direction: column;
}
.container-css .right .message-field .user-message .message-css .message .name-css {
  padding-bottom: 4px;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  line-height: 12px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.container-css .right .message-field .user-message .message-css .message .msg-css {
  padding: 10px;
  background: #FFFFFF;
  border-radius: 8px 8px 8px 8px;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.container-css .right .message-field .user-message .time-css {
  padding: 20px 0;
  font-weight: 400;
  font-size: 10px;
  color: #999999;
  line-height: 10px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}
.container-css .right .message-field .sys-message {
  padding-bottom: 20px;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.container-css .right .message-field .sys-message .message-css {
  padding: 8px;
  background: #E5EBF4;
  border-radius: 4px 4px 4px 4px;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  line-height: 12px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.container-css .right .input-field {
  width: 100%;
  height: 100px;
  background: #F5F7FA;
  border-radius: 0 0 2px 2px;
  border-top: 1px solid #E4E7ED;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
}
.container-css .right .input-field .input-css {
  width: 90%;
  padding: 12px;
}
.container-css .right .input-field .send-css {
  min-width: 76px;
  display: inline-flex;
  flex-direction: column-reverse;
  padding: 12px;
}
.container-css .right .input-field .send-css .button-css {
  max-width: 52px;
  background: #24B69B;
  border-radius: 4px 4px 4px 4px;
  padding: 8px 12px;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  line-height: 14px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}
